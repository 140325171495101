<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="d-flex justify-content-center">
        <!-- breadcrumb -->
        <nav class="w90">
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li
              v-if="getUrlParameter('tesouraria')"
              class="fd-app-breadcrumb-item active"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>
            </li>
            <li
              v-else
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Solicitações de matrícula</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div>
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left w90 mx-auto">
                <h1 class="aluno_font_color">
                  Solicitações de
                  <span>matrícula</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4 w90 mx-auto">
                <transition name="slide-fade">
                    <div v-if="fastNavegacao.exibirSolicitacoes" class="col-12 mt-2">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                                Lista de solicitações ({{ fastSecretariaPaginacao.total_results }})
                            </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            
                            </div>
                        </div>
                        </div>
                        <div class="card-body">
                          <div class="row col-12">
                            <div class="col-12">
                              <h6>Filtros</h6>
                            </div>
                            <div class="col-4 my-2">
                              <select class="form-control fc-flex" name="turmaMatricula" id="turmaMatricula" v-model="fastFiltros.turma">
                                <option value="">Qualquer turma</option>
                                <option v-for="turma in organizaTurma" :key="turma.id_turma" :value="turma.id_turma">{{ turma.nome_curso }} - {{ turma.sigla_turma }}</option>
                              </select>                            
                            </div>
                            <div class="col-4 my-2">
                              <input type="text" class="form-control fc-flex" placeholder="Filtrar por matrícula..." v-model="fastFiltros.matricula">
                            </div>
                            <div class="col-4 my-2">
                              <div class="col-sm-12 col-md-12 col-lg-12 px-0" style="position: relative">
                              <input 
                                :class="fastFiltros.buscaPessoa.length ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                                v-model="fastFiltros.buscaPessoa" 
                                type="text" 
                                placeholder="Filtrar por pessoa..."
                                @input="filtraPessoaSugestao(fastFiltros.sugerePessoa,'fastFiltros.sugerePessoa')"
                                @keyup.esc="selecionaPessoaSugerida('')"
                              >
                              <ul v-if="fastFiltros.sugerePessoa.length" class="sugestao-lista">
                                <li 
                                class="sugestao-item"
                                v-for="u in fastFiltros.sugerePessoa" 
                                :key="u.id_usuario" 
                                @click="selecionaPessoaSugerida(u), selecionaPessoaSugerida('')">
                                  {{ u.nome_razao }} {{ u.sobrenome_fantasia }} - {{ u.email }}
                                </li>
                              </ul>
                            </div>
                            </div>
                            <div class="col-4 my-2">
                              <select class="form-control fc-flex" name="canalMatricula" id="canalMatricula" v-model="fastFiltros.canal">
                                <option value="">Qualquer canal</option>
                                <option v-for="canal in fastCanais" :key="canal.Id_canal_matricula" :value="canal.Id_canal_matricula">{{ canal.Nome_canal }}</option>
                              </select>                                 
                            </div>
                            <div class="col-4 my-2">
                              <select class="form-control fc-flex" name="solicitacaoAtendida" id="solicitacaoAtendida" v-model="fastFiltros.solicitacaoAtendida">
                                <option value="">Todas as solicitações</option>
                                <option value="A">Somente atendidas</option>
                                <option value="P">Somente pendentes</option>
                                <option value="C">Somente canceladas</option>
                              </select>
                            </div>
                            <div class="col-4 my-2 d-flex date-picker">
                              <b-form-datepicker 
                              class="align-self-start"
                              size="sm"
                              v-model="fastFiltros.dataInicial" 
                              locale="pt-BR"
                              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                              label-no-date-selected="Selecionar data"
                              placeholder="D. inicial"
                              label-help="Use o cursor para selecionar"
                              ></b-form-datepicker>
                              <small class="align-self-start pt-1 px-2">até</small>
                              <b-form-datepicker 
                              class="align-self-start"
                              size="sm"
                              v-model="fastFiltros.dataFinal"
                              locale="pt-BR"
                              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                              label-no-date-selected="Selecionar data"
                              placeholder="D. final"
                              label-help="Use o cursor para selecionar"
                              ></b-form-datepicker>
                            </div>      
                            <div class="col-12 my-3 d-flex justify-content-end">
                              <a class="btn-novo btn-secondary px-2 mx-2 text-white" @click="limpaFiltros()">Limpar filtros</a>
                              <a class="btn-novo btn-primary px-2 mx-2 text-white" @click="getSecretariaSolicitacoesMatricula()">Filtrar</a>
                            </div>
                          </div>
                        <div class="row">
                            <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                                <thead class="thead-dark">
                                <tr class="text-center">
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Canal</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Data</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Matrícula</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Aluno</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Curso</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Turma</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold text-nowrap"
                                    >Valor Matric</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold text-nowrap"
                                    >Data Pgto Matric</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold text-nowrap"
                                    >Valor Curso</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold"
                                    >Status</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold text-nowrap"
                                    >Doc. gerado</small>
                                    </th>
                                    <th>
                                    <small
                                        class="font-weight-bold text-nowrap"
                                    >Doc. assinado</small>
                                    </th>
                                    <th class="text-center">
                                    <small class="font-weight-bold">Analisar</small>
                                    </th>
                                    <!-- <th class="text-center">
                                    <small class="font-weight-bold">Aprovar</small>
                                    </th> -->
                                    <th class="text-center">
                                    <small class="font-weight-bold">Cancelar</small>
                                    </th>
                                    <th class="text-center">
                                    <small class="font-weight-bold">Checkout</small>
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="fastSecretariaSolicitacoesLoadingDasRequisicoes">
                                <tr>
                                    <td
                                    colspan="12"
                                    class="text-center"
                                    >
                                    Carregando...
                                    </td>
                                </tr>
                                </tbody>
                                <tbody v-else-if="fastSecretariaSolicitacoes">
                                  <tr
                                    v-for="s in fastSecretariaSolicitacoes"
                                    :key="s.id_matricula_solicitacao"
                                >
                                <td class="text-center">
                                  <small>
                                    {{s.nome_canal}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    {{formataDataT(s.data_solicitacao)}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    #{{s.id_matricula}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    {{s.nome_aluno}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    {{s.nome_curso}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    {{s.nome_turma}}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small>
                                    R$ {{ s.valor_matricula }}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small v-if="s.data_pagto_matricula" class="text-success">
                                    {{formataDataT(s.data_pagto_matricula)}}
                                  </small>
                                  <small v-else class="text-danger">
                                    Não pago
                                  </small>
                                </td>
                                <td class="text-center d-flex align-items-center">
                                  <small>
                                    R$ {{ s.valor_curso }}
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small v-if="!s.atendida && !s.cancelada" class="text-warning">
                                    Pendente
                                  </small>
                                  <small v-else-if="!s.atendida && s.cancelada" class="text-danger">
                                    Cancelada
                                  </small>
                                  <small v-else-if="s.atendida && !s.cancelada" class="text-success">
                                    Aprovada
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small v-if="s.documento_gerado" class="text-success">
                                    Sim
                                  </small>
                                  <small v-else class="text-danger">
                                    Não
                                  </small>
                                </td>
                                <td class="text-center">
                                  <small v-if="s.documento_assinado" class="text-success">
                                    Sim
                                  </small>
                                  <small v-else class="text-danger">
                                    Não
                                  </small>
                                </td>
                                <td class="text-center">
                                    <img :src="require('@/assets/images/app/secretaria-solicitacoes-matricula-analisar.png')" class="img-solicitacoes" @click="exibeModalDetalhesSolicitacao(s)" >
                                </td>
                                <!-- <td v-if="s.atendida || s.cancelada">-</td>
                                <td v-else class="text-center">
                                    <img :src="require('@/assets/images/app/secretaria-solicitacoes-matricula-aprovar.png')" class="img-solicitacoes" @click="exibeModalAprovaSolicitacao(s)" >
                                </td> -->
                                <td v-if="s.atendida || s.cancelada">-</td>
                                <td v-else class="text-center">
                                    <img :src="require('@/assets/images/app/secretaria-solicitacoes-matricula-rejeitar.png')" class="img-solicitacoes" @click="exibeModalCancelaSolicitacao(s)" >
                                </td>
                                <td v-if="s.fast_secretaria_turma_checkout_aluno" class="text-center">
                                  <b-dropdown size="sm" variant="light rounded">
                                    <b-dropdown-item href="#" @click.prevent="copiaURLFinalizaCadastroCheckout(s)" target="_blank">
                                     <small>Copiar Link de cadastro</small></b-dropdown-item>
                                    <b-dropdown-item href="#" @click="exibeModalDetalhesCheckout(s)"><small>Detalhes do checkout</small></b-dropdown-item>
                                  </b-dropdown>
                                </td>
                                <td v-else class="text-center">
                                  -
                                </td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                    colspan="12"
                                    class="text-center"
                                    >
                                    Nenhuma solicitação recebida
                                    </td>
                                </tr>
                                
                                </tbody>
                            </table>
                            </div>
                              <div class="text-center mx-auto">
                                <b-pagination
                                v-model="fastPage"
                                :total-rows="fastSecretariaPaginacao.total_results"
                                :per-page="fastPageSize"
                                @change="getSecretariaSolicitacoesMatricula"
                                ></b-pagination>
                              </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.detalhesSolicitacao"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Detalhes da solicitação do aluno {{fastSecretariaSolicitacao.nome_aluno}}
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12">
                          <div class="row">  
                            <div class="col-12 m-2">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <h4>Solicitação de matrícula #{{ fastSecretariaSolicitacao.id_matricula_solicitacao }} - matrícula #{{ fastSecretariaSolicitacao.id_matricula }} - {{formataDataT(fastSecretariaSolicitacao.data_solicitacao)}} - 
                                        <span v-if="!fastSecretariaSolicitacao.atendida && !fastSecretariaSolicitacao.cancelada" class="text-warning">Pendente</span>
                                        <span v-else-if="fastSecretariaSolicitacao.atendida && !fastSecretariaSolicitacao.cancelada" class="text-success">Aprovada</span>
                                        <span v-else-if="!fastSecretariaSolicitacao.atendida && fastSecretariaSolicitacao.cancelada" class="text-danger">Cancelada</span>
                                        </h4>
                                        <div v-if="fastSecretariaSolicitacao.id_usuario_matriculador">
                                          Matricula realizada por: {{ fastSecretariaSolicitacaoNomeUsuarioMatriculador }}
                                        </div>
                                        <div v-if="fastSecretariaSolicitacao.id_usuario_aprovador || fastSecretariaSolicitacao.id_usuario_cancelamento">
                                          <div v-if="fastSecretariaSolicitacao.atendida && !fastSecretariaSolicitacao.cancelada">
                                              Solicitação aprovada por: {{fastSecretariaSolicitacaoNomeUsuarioAprovou}}
                                          </div>
                                          <div v-else-if="!fastSecretariaSolicitacao.atendida && fastSecretariaSolicitacao.cancelada">
                                              Solicitação cancelada por: {{fastSecretariaSolicitacaoNomeUsuarioCancelou}}
                                            <p v-if="fastSecretariaSolicitacao.motivo_cancelamento">
                                              Motivo do cancelamento: {{fastSecretariaSolicitacao.motivo_cancelamento}}
                                            </p>
                                          </div>
                                        </div>
                                    </div>
                                </div>    
                                <div class="row mt-4">
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Turma</h6>
                                        <div class="form-group">
                                            {{'#' + fastSecretariaSolicitacao.id_turma + ' - ' + fastSecretariaSolicitacao.nome_turma}}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Curso</h6>
                                        <div class="form-group">
                                            {{fastSecretariaSolicitacao.nome_curso}}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Invoice da matrícula</h6>
                                        <div class="form-group">
                                            {{fastSecretariaSolicitacao.id_invoice_matricula}}
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Data de pagamento da matrícula</h6>
                                        <div class="form-group">
                                          <span v-if="fastSecretariaSolicitacao.data_pagto_matricula">
                                            {{formataDataT(fastSecretariaSolicitacao.data_pagto_matricula)}}
                                          </span>
                                          <span v-else class="text-danger">
                                            Não consta
                                          </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Valor curso + matrícula</h6>
                                        <div class="form-group">
                                          <span>
                                            R${{ fastSecretariaSolicitacao.valor_curso }}
                                            +
                                            R${{ fastSecretariaSolicitacao.valor_matricula }}
                                          </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6 col-lg-4">
                                        <h6>Tipo de matricula</h6>
                                        <div class="form-group">
                                            <span v-if="fastSecretariaSolicitacao.matricula_por_programa">{{fastSecretariaSolicitacao.modulos}} módulos</span>
                                            <span v-else-if="fastSecretariaSolicitacao.matricula_por_unidade">{{fastSecretariaSolicitacao.unidades_curriculares}} unidades curriculares</span>
                                            <span v-else>Curso completo</span>
                                        </div>
                                    </div>
                                </div>                             
                            </div>
                            <fast-cadastro-pessoa v-if="fastSecretariaSolicitacao.id_pessoa_aluno" :readonly="false" :perfil="'aluno'" :id_pessoa="parseInt(fastSecretariaSolicitacao.id_pessoa_aluno)" :id_plataforma="parseInt($route.params.id_plataforma)" />
                            <fast-cria-documentos v-if="!fastSecretariaSolicitacaoDetalhes.fast_secretaria_matricula_aluno.id_assinatura_plataforma_documento && !fastSecretariaSolicitacao.atendida && !fastSecretariaSolicitacao.cancelada"
                              :id_pessoa="parseInt(fastSecretariaSolicitacao.id_pessoa_aluno)" 
                              :id_plataforma="parseInt($route.params.id_plataforma)" 
                              :id_matricula="parseInt(fastSecretariaSolicitacao.id_matricula)"
                              :id_secretaria_curso="parseInt(fastSecretariaSolicitacaoDetalhes.fast_secretaria_turma.Id_secretaria_curso)"
                            />
                            <div v-if="fastSecretariaSolicitacaoDetalhes.fast_secretaria_matricula_aluno.id_assinatura_plataforma_documento" class="col-12">
                              <h3 class="my-2 ml-4">Status assinatura</h3>
                              <fast-consulta-documentos-assinatura 
                              :id_assinatura_matricula="parseInt(fastSecretariaSolicitacaoDetalhes.fast_secretaria_matricula_aluno.id_assinatura_plataforma_documento)" 
                              :id_plataforma="parseInt($route.params.id_plataforma)"
                              :id_matricula="parseInt(fastSecretariaSolicitacao.id_matricula)" 
                              class="my-4"
                              />          
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0 d-flex justify-content-between">
                                <button
                                    class="btn btn-red-hollow"
                                    @click="alteraFastNavegacao('exibirSolicitacoes')"

                                  >
                                    <b-icon-arrow-return-left />
                                    Voltar para menu
                                  </button>
                                  <span
                                  v-if="fastSecretariaSolicitacao.documento_assinado && !fastSecretariaSolicitacao.atendida && !fastSecretariaSolicitacao.cancelada"
                                  >
                                    <button
                                      href="#"
                                      type="button"
                                      class="btn btn-success mr-1"
                                      @click="showModal('modalAprovarSolicitacao')"
                                    >
                                    <small>
                                      Aprovar solicitação de matrícula e enviar email de boas vindas
                                    </small>
                                    </button>
                                  </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.detalhesCheckout"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Detalhes do checkout
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  Informação
                                </th>
                                <th>
                                  Valor
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-center">
                                  ID Invoice
                                </td>
                                <td class="text-center">
                                  #{{ fastCheckoutData.id_invoice }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  ID Matrícula
                                </td>
                                <td class="text-center">
                                  #{{ fastCheckoutData.id_matricula }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  Data de confirmação do pagamento
                                </td>
                                <td class="text-center">
                                  {{ new Date(fastCheckoutData.data_registro).toLocaleString() }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  Data de acesso
                                </td>
                                <td v-if="fastCheckoutData.cadastro_acessou" class="text-center">
                                  {{ new Date(fastCheckoutData.cadastro_acessou_data).toLocaleString() }}
                                </td>
                                <td v-else class="text-center">
                                  Ainda não acessou o cadastro.
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  Data de conclusão do cadastro
                                </td>
                                <td v-if="fastCheckoutData.cadastro_concluiu" class="text-center">
                                  {{ new Date(fastCheckoutData.cadastro_concluiu_data).toLocaleString() }}
                                </td>
                                <td v-else class="text-center">
                                  Ainda não concluiu o cadastro.
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  Ocorrência de erros
                                </td>
                                <td v-if="fastCheckoutData.erro" class="text-center">
                                  {{ fastCheckoutData.erro_descricao }}
                                </td>
                                <td v-else class="text-center">
                                  Sem erros detectados durante o processo.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div>
                            <button
                              class="btn btn-red-hollow"
                              @click="alteraFastNavegacao('exibirSolicitacoes')"
  
                            >
                              <b-icon-arrow-return-left />
                              Voltar para menu
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCancelaSolicitacaoMatricula"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Cancelar a solicitação referente a matrícula {{ fastSecretariaSolicitacao.id_matricula }}</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCancelaSolicitacaoMatricula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div v-if="fastSecretariaSolicitacoesLoading" class="col-12 text-center justify-content-center">
          <b-icon
            icon="gear-fill"
            animation="spin"
          /> Carregando
          </div>
        <div v-else class="col-12 text-center justify-content-center">
          <div class="pt-4 pr-4 pb-0 pl-4text-center">
            Informe um motivo para cancelar a solicitação referente a matrícula {{ fastSecretariaSolicitacao.id_matricula }}
          </div>
          <div>
            <textarea class="form-control" placeholder="Informe o motivo aqui..." v-model="fastSecretariaSolicitacaoCancelamentoMotivo"></textarea>
          </div>
          <div class="pt-4 pr-4 pb-0 pl-4 text-center">
            <button class="btn btn-danger" @click="cancelaSolicitacaoMatricula()">Cancelar</button>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAprovarSolicitacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Confirmação para aprovar solicitação de matrícula</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAprovarSolicitacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div v-if="fastSecretariaSolicitacoesLoading" class="col-12 text-center justify-content-center">
          <b-icon
            icon="gear-fill"
            animation="spin"
          /> Carregando
          </div>
        <div v-else class="col-12 text-center justify-content-center">
          <div v-if="fastSecretariaSolicitacao.data_pagto_matricula" class="pt-4 pr-4 pb-0 pl-4text-center">
            <p>
              O pagamento da taxa de matrícula referente a matrícula {{ fastSecretariaSolicitacao.id_matricula }} ainda não foi processado.
            </p>
            <p>
              Deseja aprovar a solicitação de matrícula mesmo assim?
            </p>
          </div>
          <div class="my-2">
            <select class="form-control" v-model="fastSecretariaSolicitacaoAtivaMatricula">
              <option value='T'>Ativar matrícula ao aprovar solicitação</option>
              <option value='F'>Não ativar matrícula, será ativada manualmente</option>
            </select>
          </div>
          <div class="pt-4 pr-4 pb-0 pl-4 text-center">
            <button
              href="#"
              type="button"
              class="btn btn-success mr-1"
              @click="aprovaSolicitacaoMatricula()"
            >
            <small>
              Aprovar solicitação de matrícula e enviar email de boas vindas
            </small>
            </button>          
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import FastCadastroPessoa from "../components/FastCadastroPessoa";
import FastCriaDocumentos from "../components/FastCriaDocumentos";
import FastConsultaDocumentosAssinatura from "../components/FastConsultaDocumentosAssinatura";

export default {
  name: "HomeInternoSecretariaSolicitacoesMatricula",
  components: {
    FastCadastroPessoa,
    FastCriaDocumentos,
    FastConsultaDocumentosAssinatura
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      //Navegação entre paginas
      fastNavegacao: {
        exibirSolicitacoes: true,
        detalhesSolicitacao: false,
        detalhesCheckout: false,
        aprovaSolicitacao: false
      },
      // Solicitacoes
      fastSecretariaSolicitacoes: [],
      fastPage: 1,
      fastPageSize: 10,
      fastSecretariaSolicitacoesLoading: true,   
      fastSecretariaSolicitacoesLoadingDasRequisicoes: true,   
      fastSecretariaSolicitacao: {
        id_usuario_aprovador: '',
        id_usuario_cancelamento: '',
      },
      fastSecretariaSolicitacaoDetalhes: {
        fast_secretaria_matricula_aluno: {
          id_assinatura_plataforma_documento: "",
        },
        fast_secretaria_turma: {
          Id_secretaria_curso: "",
        }
      },
      fastSecretariaSolicitacaoNomeUsuarioMatriculador: '',
      fastSecretariaSolicitacaoNomeUsuarioCancelou: '',
      fastSecretariaSolicitacaoNomeUsuarioAprovou: '',
      fastSecretariaSolicitacaoCancelamentoMotivo: '',
      fastSecretariaSolicitacaoAtivaMatricula: 'T',
      fastSecretariaPaginacao: {
        success: false,
        range_start: 0,
        range_end: 0,
        total_results: 0,
        error: null,
        description: null,
      },
      fastCanais: [],
      fastTurmas: [],
      fastFiltros: {
        turma: '',
        matricula: '',
        sugerePessoa: '',
        buscaPessoa: '',
        idPessoa: '',
        canal: '',
        solicitacaoAtendida: 'P',
        dataInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
        dataFinal: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      },
      fastCheckoutData: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.$store.state.fastControlaComponentes.showHideExcluiDocumentoAutenticacao = false
            this.$store.state.fastControlaComponentes.showHideAprovarSolicitacaoMatricula = false
            this.getSecretariaSolicitacoesMatricula();
            this.getCanais();
            this.getTurmas();
            this.getEmailMatricula();
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  computed: {
    organizaTurma(){
      const listaTurmas = [...this.fastTurmas]
      return listaTurmas.sort(this.reornedarArrayObjetos("nome_curso"))
    },
    showHideConsultaDocumentoAuteticacao() {
      return this.$store.state.fastControlaComponentes.showHideConsultaDocumentoAuteticacao;
    },
    showHideAprovarSolicitacaoMatricula() {
      return this.$store.state.fastControlaComponentes.showHideAprovarSolicitacaoMatricula;
    },
  },
  watch: {
    showHideConsultaDocumentoAuteticacao(newValue) {
      if (newValue === true) {
        this.getDetalhesSecretariaSolicitacaoMatricula(this.fastSecretariaSolicitacao.id_matricula_solicitacao);
      }
    },
    showHideAprovarSolicitacaoMatricula(newValue) {
      if (newValue === true) {
        this.getDetalhesSecretariaSolicitacaoMatricula(this.fastSecretariaSolicitacao.id_matricula_solicitacao);
        this.fastSecretariaSolicitacao.documento_assinado = true
      }
    }
  },
  methods: {
    async getNomeUsuarioMatriculou(){
      if (this.fastSecretariaSolicitacao.id_usuario_matriculador) {
        this.fastSecretariaSolicitacoesLoading = true
        await this.promiseGetFastApi("api/fast_plataforma_usuario/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_usuario=${this.fastSecretariaSolicitacao.id_usuario_matriculador}`)
          .then(obj => {
            if (obj){
              let nome_user
              if (obj[0].nome_razao && obj[0].sobrenome_fantasia){
                nome_user = `${obj[0].nome_razao} ${obj[0].sobrenome_fantasia}`
              }
              this.fastSecretariaSolicitacaoNomeUsuarioMatriculador = nome_user
              this.fastSecretariaSolicitacoesLoading = false
            }
          }).catch(e => {
            console.log(e);
            this.fastSecretariaSolicitacoesLoading = false
          })
      }
    },
    async getNomeUsuarioCancelou(){
      if (this.fastSecretariaSolicitacao.id_usuario_cancelamento) {
        this.fastSecretariaSolicitacoesLoading = true
        await this.promiseGetFastApi("api/fast_plataforma_usuario/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_usuario=${this.fastSecretariaSolicitacao.id_usuario_cancelamento}`)
          .then(obj => {
            if (obj){
              let nome_user
              if (obj[0].nome_razao && obj[0].sobrenome_fantasia){
                nome_user = `${obj[0].nome_razao} ${obj[0].sobrenome_fantasia}`
              }
              this.fastSecretariaSolicitacaoNomeUsuarioCancelou = nome_user
              this.fastSecretariaSolicitacoesLoading = false
            }
          }).catch(e => {
            console.log(e);
            this.fastSecretariaSolicitacoesLoading = false
          })
      }
    },
    async getNomeUsuarioAprovou(){
      this.fastSecretariaSolicitacoesLoading = true
      if (this.fastSecretariaSolicitacao.id_usuario_aprovador) {
        await this.promiseGetFastApi("api/fast_plataforma_usuario/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_usuario=${this.fastSecretariaSolicitacao.id_usuario_aprovador}`)
          .then(obj => {
            if (obj){
              let nome_user
              if (obj[0].nome_razao && obj[0].sobrenome_fantasia){
                nome_user = `${obj[0].nome_razao} ${obj[0].sobrenome_fantasia}`
              }
              this.fastSecretariaSolicitacaoNomeUsuarioAprovou = nome_user
              this.fastSecretariaSolicitacoesLoading = false
            }
          }).catch(e => {
            console.log(e);
            this.fastSecretariaSolicitacoesLoading = false
          })
      }
    },
    getSecretariaSolicitacoesMatricula(){
      this.fastSecretariaSolicitacoesLoadingDasRequisicoes = true
      let solicitacaoAprovada
      let solicitacaoCancelada
      if (this.fastFiltros.solicitacaoAtendida === ''){
        solicitacaoAprovada = ''
        solicitacaoCancelada = ''
      } else if (this.fastFiltros.solicitacaoAtendida === 'P'){ 
        solicitacaoAprovada = '&atendida=false'
        solicitacaoCancelada = '&cancelada=false'
      } else if (this.fastFiltros.solicitacaoAtendida === 'C'){
        solicitacaoAprovada = '&atendida=false'
        solicitacaoCancelada = '&cancelada=true'
      } else if (this.fastFiltros.solicitacaoAtendida === 'A'){
        solicitacaoAprovada = '&atendida=true'
        solicitacaoCancelada = ''
      }
      this.$nextTick(() => {	
        this.promiseGetFastApi("api/fast_secretaria_matricula_aluno_solicitacao/lista_filtro", `id_plataforma=${this.$route.params.id_plataforma}&page_number=${this.fastPage}&page_size=${this.fastPageSize}${this.fastFiltros.turma ? '&id_turma=' + this.fastFiltros.turma : ''}${this.fastFiltros.matricula ? '&id_matricula=' + this.fastFiltros.matricula : ''}${this.fastFiltros.idPessoa ? '&id_pessoa_aluno=' + this.fastFiltros.idPessoa : ''}${this.fastFiltros.canal ? '&id_canal_matricula=' + this.fastFiltros.canal : ''}${this.fastFiltros.dataInicial ? '&data_start=' + this.fastFiltros.dataInicial : ''}${this.fastFiltros.dataFinal ? '&data_end=' + this.fastFiltros.dataFinal : ''}${solicitacaoAprovada}${solicitacaoCancelada}`).then(obj => {
          console.log("getSecretariaSolicitacoesMatricula", obj);
          if (obj) {
            this.fastSecretariaSolicitacoes = obj.data;
            this.fastSecretariaPaginacao.success = obj.success
            this.fastSecretariaPaginacao.range_start = obj.range_start
            this.fastSecretariaPaginacao.range_end = obj.range_end
            this.fastSecretariaPaginacao.total_results = obj.total_results
            this.fastSecretariaPaginacao.error = obj.error
            this.fastSecretariaPaginacao.description = obj.description
            this.fastSecretariaSolicitacoesLoadingDasRequisicoes = false
          }
        }).catch(e => {
          console.log(e);
          this.fastSecretariaSolicitacoesLoadingDasRequisicoes = false
        })
      })
    },
    getDetalhesSecretariaSolicitacaoMatricula(id_matricula_solicitacao){
      this.fastSecretariaSolicitacoesLoading = true
       this.promiseGetFastApi("api/fast_secretaria_matricula_aluno_solicitacao/lista_id", `id_plataforma=${this.$route.params.id_plataforma}&id_matricula_solicitacao=${id_matricula_solicitacao}`).then(obj => {
          // console.log("getSecretariaSolicitacoesMatricula", obj);
          if (obj) {
            this.fastSecretariaSolicitacaoDetalhes = obj;            
          }
          this.getNomeUsuarioMatriculou();
          this.getNomeUsuarioCancelou();
          this.getNomeUsuarioAprovou();
          this.fastSecretariaSolicitacoesLoading = false
        }).catch(e => {
          console.log(e);
          this.fastSecretariaSolicitacoesLoading = false
        })
    },
    async getTurmas(){
      this.fastSecretariaSolicitacoesLoading = true
        await this.promiseGetFastApi("api/fast_secretaria_turma/lista", `id_plataforma=${this.$route.params.id_plataforma}`).then(obj => {
          if (obj) {
              this.fastTurmas = obj
            }
          this.fastSecretariaSolicitacoesLoading = false
        }).catch(e => {
          console.log(e);
          this.fastSecretariaSolicitacoesLoading = false
        })
    },
    async getCanais(){
      this.fastSecretariaSolicitacoesLoading = true
        await this.promiseGetFastApi("api/fast_secretaria_matricula_canal/lista", `id_plataforma=${this.$route.params.id_plataforma}`).then(obj => {
          if (obj) {
              this.fastCanais = obj
            }
          this.fastSecretariaSolicitacoesLoading = false
        }).catch(e => {
          console.log(e);
          this.fastSecretariaSolicitacoesLoading = false
        })
    },
    async getEmailMatricula(){
      this.fastSecretariaSolicitacoesLoading = true
        await this.promiseGetFastApi("api/fast_email_template_plataforma/lista_codigo", `id_plataforma=${this.$route.params.id_plataforma}&codigo_email_template=EMAIL-CONFIRMACAO-MATRICULA`).then(obj => {
          if (obj) {
              this.fastEmailConfirmacaoMatricula = obj
            }
          this.fastSecretariaSolicitacoesLoading = false
        }).catch(e => {
          console.log(e);
          this.fastSecretariaSolicitacoesLoading = false
        })
    },
    limpaFiltros() {
        this.fastFiltros.turma = ''
        this.fastFiltros.matricula = ''
        this.fastFiltros.sugerePessoa = ''
        this.fastFiltros.buscaPessoa = ''
        this.fastFiltros.idPessoa = ''
        this.fastFiltros.canal = ''
        this.fastFiltros.solicitacaoAtendida = ''
        this.fastFiltros.dataInicial = ''
        this.fastFiltros.dataFinal = ''
        this.getSecretariaSolicitacoesMatricula()
    },
    filtraPessoaSugestao(){
      if (this.fastFiltros.buscaPessoa.length >= 3) {
        setTimeout(() => {
          this.promiseGetFastApi("api/fast_pessoa/lista_paginado", `PageIndex=1&PageSize=20&id_plataforma=${this.$route.params.id_plataforma}&nome_pessoa=${this.fastFiltros.buscaPessoa}`)
          .then((res) => {          
            if (res.Data.length) {
              this.fastFiltros.sugerePessoa = res.Data;
              }
            })
            .catch((e) => {
                console.log(e);
            });
          }, 1000)
        } else {
          this.fastFiltros.sugerePessoa = []
      }
    },
    selecionaPessoaSugerida(usuario) {
      this.fastFiltros.sugerePessoa = '';
        if(usuario == ''){
          this.fastFiltros.sugerePessoa = '';
        }else{
          this.fastFiltros.sugerePessoa = usuario.nome_razao + " " + usuario.sobrenome_fantasia;
          this.fastFiltros.buscaPessoa = usuario.nome_razao + " " + usuario.sobrenome_fantasia
          this.fastFiltros.idPessoa = usuario.id_pessoa;
        }
    },
    copiaURLFinalizaCadastroCheckout(solicitacao) {
      const el = document.createElement('textarea');  
      el.value = `${settings.endFastEad}cadastro-aluno?token=${solicitacao.fast_secretaria_turma_checkout_aluno.token}`;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      this.exibeToasty('Boleto copiado para a Área de Transferência!','success')
    },
    exibeModalDetalhesCheckout(obj){
      this.fastCheckoutData = obj.fast_secretaria_turma_checkout_aluno
      this.alteraFastNavegacao('detalhesCheckout')
    },    
    exibeModalDetalhesSolicitacao(obj) {
      this.fastSecretariaSolicitacao = {}
      this.getDetalhesSecretariaSolicitacaoMatricula(obj.id_matricula_solicitacao)
      this.fastSecretariaSolicitacao = obj;
      this.alteraFastNavegacao('detalhesSolicitacao')
    },
    exibeModalCancelaSolicitacao(obj){
      this.fastSecretariaSolicitacao = obj;
      this.showModal('modalCancelaSolicitacaoMatricula')
    },
    aprovaSolicitacaoMatricula(){
      this.fastSecretariaSolicitacoesLoading = true
      let obj = {
        id_matricula_solicitacao: this.fastSecretariaSolicitacao.id_matricula_solicitacao,
        id_plataforma: this.$route.params.id_plataforma,
        atendida: true,
        ativar_matricula: this.fastSecretariaSolicitacaoAtivaMatricula === 'T' ? true : false
      }
      this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_solicitacao/atualiza")
      .then(res => {
        // console.log(res)
        this.exibeToasty("Solicitação aprovada", "success")
        this.alteraFastNavegacao('exibirSolicitacoes')
        this.getSecretariaSolicitacoesMatricula()
        this.enviaEmailConfirmacaoMatricula()
        this.hideModal('modalAprovarSolicitacao')
        this.fastSecretariaSolicitacoesLoading = false
        })
        .catch((e) => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastSecretariaSolicitacoesLoading = false
        })
    },
    enviaEmailConfirmacaoMatricula(){
      let obj = {
        id_matricula_solicitacao: this.fastSecretariaSolicitacao.id_matricula_solicitacao,
        id_plataforma: this.$route.params.id_plataforma
      }
      this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_solicitacao/envia_email_confirmacao")
      .then(res => {
        // console.log(res)
        })
        .catch((e) => {
          console.log(e)
          this.exibeToasty(e, "error")
        })
    },
    cancelaSolicitacaoMatricula(){
      if (!this.fastSecretariaSolicitacaoCancelamentoMotivo) {
        this.exibeToasty("Por favor, especifique o motivo para cancelar esta solicitação de matrícula.", "error")
      } else {
        this.fastSecretariaSolicitacoesLoading = true
        let obj = {
          id_matricula_solicitacao: this.fastSecretariaSolicitacao.id_matricula_solicitacao,
          id_plataforma: this.$route.params.id_plataforma,
          motivo_cancelamento: this.fastSecretariaSolicitacaoCancelamentoMotivo,
          cancelada: true,
        }
        this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_solicitacao/atualiza")
        .then(res => {
          this.exibeToasty("Solicitação cancelada", "success")
          this.hideModal('modalCancelaSolicitacaoMatricula')
          this.getSecretariaSolicitacoesMatricula()
          this.fastSecretariaSolicitacaoCancelamentoMotivo = ''
          this.fastSecretariaSolicitacoesLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.exibeToasty(e, "error")
            this.fastSecretariaSolicitacoesLoading = false
          })
      }
    },
    validaDadosMatricula() {
      let erros = [];
      if (!this.$store.state.fastPessoa.first_name)
        erros.push(
          "O nome do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.last_name)
        erros.push(
          "O sobrenome do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cpf_cnpj)
        erros.push(
          "O CPF/CNPJ do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.logradouro)
        erros.push(
          "O endereço do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.numero)
        erros.push(
          "O número do endereço do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.bairro)
        erros.push(
          "O bairro do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cep)
        erros.push(
          "O cep do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cidade)
        erros.push(
          "O cidade do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.uf)
        erros.push(
          "O estado do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.telefone)
        erros.push(
          "O telefone do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.datanascimento_abertura)
        erros.push(
          "A data de nascimento do aluno não foi cadastrada. Verifique os dados pessoais."
        );

        if (this.$store.state.fastPessoa.alteracao_pendente) erros.push("Salve as alterações pendentes.");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
         
        window.location.href =
          "/plataforma/" +
          this.$route.params.id_plataforma +
          "/secretaria/matricula/" +
          this.fastSecretariaSolicitacao.id_pessoa_aluno + "?id_matricula_solicitacao=" + this.fastSecretariaSolicitacao.id_matricula_solicitacao;
          
      }
    },
    setImageEmailConfirmacaoMatricula(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "email", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else {
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email             
                this.fastEmailAvaliacao.html_email += `<img src="${retorno.url_image}" style="max-width: 100%">`
                this.exibeToasty("Imagem inserida com sucesso", "success");
              }
            }
          }).catch(e => {
            console.log(e);
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

<style scoped>
.w90 {
  width: 90%;
}
.img-solicitacoes {
  height: 25px; 
  width: 25px;
  cursor: pointer;
}
.fc-flex {
  display: flex;
}
.sugestao-lista {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-top: none;
  padding: 0;
  margin: 0;
  z-index: 999;
}
.sugestao-item {
  list-style-type: none;
  padding: 3px 6px;
  cursor: pointer;
  color: rgb(95, 95, 95);
}
.sugestao-item:hover {
  transition: 0.3s;
  background-color: #e8e8e8;
}
@media only screen and (max-width: 990px) {
  .date-picker label {
    display: none;
  }
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.ql-container {
  height: auto !important;
  min-height: 100px;
}
</style>
